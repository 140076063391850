
<script setup lang="ts">
    const WIDTH: number = 5;
    const HEIGHT: number = 5;

    const grid = [
        [0, 2, 2, 2, 2],
        [0, 0, 2, 2, 2],
        [1, 0, 0, 2, 2],
        [1, 1, 0, 0, 2],
        [1, 1, 1, 0, 0],
    ];

    const A = [3, 2];
    const B = [1, 3];

    function getClass(num) {
        const classes = ["d-flex justify-content-center align-items-center position-relative"];
        return classes.join(" ");
    }

    function getStyle(num) {
        const style = {};
        style.width = "2rem";
        style.height = "2rem";
        if (num === 0) {
            style.backgroundColor = "#F0E68C";
        } else if (num === 1) {
            style.backgroundColor = "#8FBC8F";
        } else if (num === 2) {
            style.backgroundColor = "#FF6347";
        }
        return style;
    }
</script>

<template>
    <div class="p-3">
        <div class="d-flex" style="padding-top: 1.75rem; padding-left: 1rem; padding-bottom: 1.75rem; padding-right: 1rem;">
            <div class="d-flex flex-column" style="padding: 1px; gap: 1px; background-color: #D3D3D3;">
                <template v-for="(row, y) in grid">
                    <div class="d-flex" style="gap: 1px;">
                        <template v-for="(num, x) in row">
                            <div :class="getClass(num)" :style="getStyle(num)">
                                <!-- left -->
                                <template v-if="x === 0">
                                    <div style="position: absolute; left: -1rem; top: 50%; transform: translate(0%, -50%);">
                                        {{ 5 - y }}
                                    </div>
                                </template>

                                <!-- top -->
                                <template v-if="y === 0">
                                    <div style="position: absolute; top: -1.75rem; left: 50%; transform: translate(-50%, 0%);">
                                        {{ x + 1 }}
                                    </div>
                                </template>
                                
                                <!-- bottom -->
                                <template v-if="x === 2 && y === 4">
                                    <div style="position: absolute; bottom: -1.75rem; left: 50%; transform: translate(-50%, 0%);">
                                        Consequence
                                    </div>
                                </template>
                                
                                <!-- right -->
                                <template v-if="x === 4 && y === 2">
                                    <div style="position: absolute; right: -3.5rem; left: 50%; transform: translate(0%, 0%) rotate(90deg);">
                                        Probability
                                    </div>
                                </template>

                                <!-- A -->
                                <template v-if="x === A[0] && y === A[1]">
                                    <div class="underline">A</div>
                                </template>

                                <!-- B -->
                                <template v-if="x === B[0] && y === B[1]">
                                    <div>B</div>
                                </template>
                            </div>
                        </template>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
